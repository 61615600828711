
























































































































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'

import { ELSA_API_LOCATION } from '@/api'
import {
  getValmistumispyyntoOsaamisenArviointi,
  getValmistumispyyntoArviointienTila,
  putValmistumispyynto
} from '@/api/vastuuhenkilo'
import ElsaButton from '@/components/button/button.vue'
import ErikoistuvaDetails from '@/components/erikoistuva-details/erikoistuva-details.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaConfirmationModal from '@/components/modal/confirmation-modal.vue'
import ValmistumispyyntoMixin from '@/mixins/valmistumispyynto'
import {
  ValmistumispyyntoArviointienTila,
  ValmistumispyyntoLomakeOsaamisenArviointi
} from '@/types'
import { confirmExit } from '@/utils/confirm'
import { toastSuccess, toastFail } from '@/utils/toast'

@Component({
  components: {
    ElsaButton,
    ElsaFormGroup,
    ElsaFormError,
    ErikoistuvaDetails,
    ElsaConfirmationModal
  }
})
export default class ValmistumispyynnonArviointi extends Mixins<ValmistumispyyntoMixin>(
  validationMixin,
  ValmistumispyyntoMixin
) {
  validations() {
    return {
      form: {
        osaaminenRiittavaValmistumiseen: {
          required
        },
        korjausehdotus: {
          required: requiredIf((value) => {
            return value.osaaminenRiittavaValmistumiseen === false
          })
        }
      }
    }
  }

  items = [
    {
      text: this.$t('etusivu'),
      to: { name: 'etusivu' }
    },
    {
      text: this.$t('valmistumispyynnot'),
      to: { name: 'valmistumispyynnot' }
    },
    {
      text: this.$t('valmistumispyynto'),
      active: true
    }
  ]

  form: ValmistumispyyntoLomakeOsaamisenArviointi = {
    id: null,
    osaaminenRiittavaValmistumiseen: null,
    korjausehdotus: null
  }

  valmistumispyyntoArviointienTila: ValmistumispyyntoArviointienTila | null = null
  loading = true
  sending = false
  skipRouteExitConfirm = true

  async mounted() {
    const valmistumispyyntoId = this.$route?.params?.valmistumispyyntoId
    if (valmistumispyyntoId) {
      try {
        await getValmistumispyyntoOsaamisenArviointi(parseInt(valmistumispyyntoId)).then(
          (response) => {
            this.valmistumispyynto = response.data
          }
        )
        if (this.odottaaOsaamisenArviointia) {
          await getValmistumispyyntoArviointienTila(parseInt(valmistumispyyntoId)).then(
            (response) => {
              this.valmistumispyyntoArviointienTila = response.data
            }
          )
        }
        this.loading = false
      } catch {
        toastFail(this, this.$t('valmistumispyynnon-hakeminen-epaonnistui'))
        this.$router.replace({ name: 'valmistumispyynnot' })
      }
    }
  }

  async vaihdaRooli(id: number | undefined) {
    if (this.odottaaOsaamisenArviointia && !this.skipRouteExitConfirm) {
      if (!(await confirmExit(this))) {
        return
      }
    }

    this.$emit('skipRouteExitConfirm', true)
    window.location.href = `${ELSA_API_LOCATION}/api/login/impersonate?opintooikeusId=${id}&originalUrl=${window.location.href}`
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    return !this.$v.$anyError
  }

  onSubmit() {
    if (!this.validateForm()) return
    return this.$bvModal.show('confirm-send')
  }

  async onSend() {
    try {
      this.sending = true
      if (this.valmistumispyynto.id) {
        this.form.id = this.valmistumispyynto.id
      }
      this.valmistumispyynto = (await putValmistumispyynto(this.form)).data
      this.$emit('skipRouteExitConfirm', true)
      toastSuccess(this, this.$t('osaamisen-arviointi-tallennettu'))
    } catch (err) {
      toastFail(this, this.$t('osaamisen-arvioinnin-tallentaminen-epaonnistui'))
    }
    this.sending = false
  }

  onSkipRouteExitConfirm() {
    this.skipRouteExitConfirm = false
    this.$emit('skipRouteExitConfirm', false)
  }
}
