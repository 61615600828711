var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bar-chart user-select-none"},[_c('b-table-simple',{staticClass:"text-size-sm mt-3 border-0",attrs:{"borderless":""}},_vm._l((_vm.value),function(row,index){return _c('b-tr',{key:index},[_c('b-td',{staticClass:"text-right text-nowrap",class:{
          'font-weight-500': row.highlight,
          'pt-2': row.highlight,
          'pt-1': !row.highlight
        }},[_vm._v(" "+_vm._s(row.text)+" ")]),_c('b-td',{staticClass:"px-2",class:{ 'pt-2': row.highlight, 'pt-1': !row.highlight },staticStyle:{"width":"100%"}},[_c('elsa-progress-bar',{attrs:{"value":row.value,"min-required":row.minRequired,"color":row.color,"background-color":row.backgroundColor}})],1),_c('b-td',{staticClass:"text-nowrap",class:{
          'font-weight-500': row.highlight,
          'pt-2': row.highlight,
          'pt-1': !row.highlight
        }},[(row.maxLength == null || row.maxLength === 0)?_c('span',[_vm._v(_vm._s(_vm.$t('vah')))]):_vm._e(),_vm._v(" "+_vm._s(_vm.$duration(row.minRequired))+" "),(row.showMax)?_c('span',[_vm._v("/ "+_vm._s(_vm.$t('enint')))]):_vm._e(),(row.showMax)?_c('span',[_vm._v(_vm._s(row.maxRequired)+" kk")]):_vm._e()])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }