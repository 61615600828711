


























































































import { parseISO, format } from 'date-fns'
import { Component, Vue } from 'vue-property-decorator'

import { ELSA_API_LOCATION } from '@/api'
import { getIlmoitukset, getSeuraavaPaivitys } from '@/api/julkinen'
import ElsaButton from '@/components/button/button.vue'
import { Ilmoitus } from '@/types'

@Component({
  components: {
    ElsaButton
  }
})
export default class Login extends Vue {
  huoltokatkoNotification = false
  HuoltokatkoDay = ''
  HuoltokatkoDate = ''
  HuoltokatkoTime = ''
  ilmoitukset: Ilmoitus[] | null = null

  async mounted() {
    this.handleHuoltokatkoDates((await getSeuraavaPaivitys()).data)
    this.ilmoitukset = (await getIlmoitukset()).data
  }

  loginSuomiFi() {
    const token = this.$route.query.token
    return (window.location.href = token
      ? `${ELSA_API_LOCATION}/saml2/authenticate/suomifi?RelayState=${this.$route.query.token}`
      : `${ELSA_API_LOCATION}/saml2/authenticate/suomifi`)
  }

  handleHuoltokatkoDates(dateStr: string) {
    if (dateStr !== '') {
      const date = parseISO(dateStr)
      this.huoltokatkoNotification = true
      this.HuoltokatkoDay = format(date, 'ccc')
      this.HuoltokatkoDate = format(date, 'dd.MM.')
      this.HuoltokatkoTime = format(date, 'HH.mm')
    }
  }

  get virhe() {
    return this.$route.query.virhe
  }

  get showMail() {
    return (
      this.$route.query.virhe === 'EI_KAYTTO_OIKEUTTA' ||
      this.$route.query.virhe === 'VIRHEELLINEN_NIMI'
    )
  }

  get noUseRights() {
    return this.$route.query.virhe === 'EI_KAYTTO_OIKEUTTA'
  }

  get invalidName() {
    return this.$route.query.virhe === 'VIRHEELLINEN_NIMI'
  }

  get contactMail() {
    return 'julia.sillanpaa@tuni.fi'
  }
}
