














































import { Component, Mixins } from 'vue-property-decorator'

import { getKayttajahallintaRajaimet } from '@/api/kayttajahallinta'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPagination from '@/components/pagination/pagination.vue'
import ElsaSearchInput from '@/components/search-input/search-input.vue'
import KayttajahallintaMixin from '@/mixins/kayttajahallinta'
import { KayttajahallintaRajaimet } from '@/types'
import { sortByAsc } from '@/utils/sort'
import { toastFail } from '@/utils/toast'
import ErikoistuvatLaakarit from '@/views/kayttajahallinta/erikoistuvat-laakarit.vue'
import Kouluttajat from '@/views/kayttajahallinta/kouluttajat.vue'
import Paakayttajat from '@/views/kayttajahallinta/paakayttajat.vue'
import Vastuuhenkilot from '@/views/kayttajahallinta/vastuuhenkilot.vue'
import Virkailijat from '@/views/kayttajahallinta/virkailijat.vue'

@Component({
  components: {
    ErikoistuvatLaakarit,
    Kouluttajat,
    Vastuuhenkilot,
    Virkailijat,
    Paakayttajat,
    ElsaButton,
    ElsaFormGroup,
    ElsaFormMultiselect,
    ElsaPagination,
    ElsaSearchInput
  }
})
export default class Kayttajahallinta extends Mixins(KayttajahallintaMixin) {
  initializing = true
  rajaimet: KayttajahallintaRajaimet | null = null

  tabIndex = 0
  tabs = [
    '#erikoistuvat-laakarit',
    '#kouluttajat',
    '#vastuuhenkilot',
    '#virkailijat',
    '#paakayttajat'
  ]

  beforeMount() {
    this.tabIndex = this.tabs.findIndex((tab) => tab === this.$route.hash)
  }

  async mounted() {
    try {
      await this.fetchRajaimet()
    } catch {
      toastFail(this, this.$t('kayttajien-hakeminen-epaonnistui'))
    }
    this.initializing = false
  }

  async fetchRajaimet() {
    const rajaimet = (await getKayttajahallintaRajaimet()).data
    this.rajaimet = {
      ...rajaimet,
      erikoisalat: rajaimet?.erikoisalat.sort((a, b) => sortByAsc(a.nimi, b.nimi))
    }
  }
}
