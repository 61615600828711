








































































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaTextEditor from '@/components/text-editor/text-editor.vue'
import { ArvioitavaKokonaisuus, ArvioitavanKokonaisuudenKategoria } from '@/types'
import { sortByAsc } from '@/utils/sort'

@Component({
  components: {
    ElsaButton,
    ElsaFormGroup,
    ElsaFormError,
    ElsaFormDatepicker,
    ElsaFormMultiselect,
    ElsaTextEditor
  }
})
export default class ArvioitavaKokonaisuusForm extends Mixins(validationMixin) {
  $refs!: {
    voimassaoloAlkaa: ElsaFormDatepicker
    voimassaoloPaattyy: ElsaFormDatepicker
  }

  @Prop({ required: false, default: false })
  editing!: boolean

  @Prop({ required: false, default: true })
  kategoriaEditable!: boolean

  @Prop({ required: true, type: Object })
  kokonaisuus!: ArvioitavaKokonaisuus

  @Prop({ required: false, type: Array, default: () => [] })
  kategoriat!: ArvioitavanKokonaisuudenKategoria[]

  params = {
    saving: false
  }

  form: Partial<ArvioitavaKokonaisuus> = {}

  mounted() {
    this.form = { ...this.kokonaisuus }
  }

  get sortedKategoriat() {
    return this.kategoriat.sort((a, b) => sortByAsc(a.nimi, b.nimi))
  }

  validations() {
    return {
      form: {
        kategoria: {
          required
        },
        nimi: {
          required
        },
        voimassaoloAlkaa: {
          required
        }
      }
    }
  }

  validateState(name: string) {
    const { $dirty, $error } = this.$v.form[name] as any
    return $dirty ? ($error ? false : null) : null
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    return !this.$v.$anyError
  }

  onSubmit() {
    const validations = [this.validateForm(), this.$refs.voimassaoloAlkaa.validateForm()]

    if (validations.includes(false)) {
      return
    }

    this.$emit(
      'submit',
      {
        ...this.form
      },
      this.params
    )
  }
}
