


























































































































































































































































































import axios from 'axios'
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import { required, between, requiredIf, integer } from 'vuelidate/lib/validators'

import AsiakirjatContent from '@/components/asiakirjat/asiakirjat-content.vue'
import AsiakirjatUpload from '@/components/asiakirjat/asiakirjat-upload.vue'
import ElsaButton from '@/components/button/button.vue'
import ElsaFormDatepicker from '@/components/datepicker/datepicker.vue'
import ElsaFormError from '@/components/form-error/form-error.vue'
import ElsaFormGroup from '@/components/form-group/form-group.vue'
import ElsaFormMultiselect from '@/components/multiselect/multiselect.vue'
import ElsaPopover from '@/components/popover/popover.vue'
import {
  Asiakirja,
  Laillistamistiedot,
  LaillistamistiedotLomakeKoulutettava,
  Tyoskentelyjakso,
  TyoskentelyjaksoForm
} from '@/types'
import { TyoskentelyjaksoTyyppi } from '@/utils/constants'
import { mapFile, mapFiles } from '@/utils/fileMapper'
import { sortByAsc } from '@/utils/sort'
import { toastFail } from '@/utils/toast'
import { tyoskentelypaikkaTyyppiLabel } from '@/utils/tyoskentelyjakso'
import KouluttajaKoulutussopimusForm from '@/views/koejakso/kouluttaja/kouluttaja-koulutussopimus-form.vue'

@Component({
  components: {
    KouluttajaKoulutussopimusForm,
    AsiakirjatContent,
    AsiakirjatUpload,
    ElsaButton,
    ElsaFormGroup,
    ElsaFormError,
    ElsaFormMultiselect,
    ElsaFormDatepicker,
    ElsaPopover
  },
  validations: {
    form: {
      tyoskentelypaikka: {
        required,
        nimi: {
          required
        },
        kunta: {
          required
        },
        tyyppi: {
          required
        },
        muuTyyppi: {
          required: requiredIf((value) => {
            return value.tyyppi === 'MUU'
          })
        }
      },
      osaaikaprosentti: {
        required,
        integer,
        between: between(50, 100)
      }
    }
  }
})
export default class YekTyoskentelyjaksoForm extends Vue {
  $refs!: {
    laillistamispaiva: ElsaFormDatepicker
    alkamispaiva: ElsaFormDatepicker
    paattymispaiva: ElsaFormDatepicker
  }
  laillistamispaivaAsiakirjat: Asiakirja[] = []

  @Prop({ required: false, default: true })
  allowHyvaksyttyAiemminToiselleErikoisalalleOption!: boolean

  @Prop({ required: false, default: false })
  editing!: boolean

  @Prop({ required: false, default: () => [] })
  kunnat!: any[]

  @Prop({ required: false, default: undefined })
  asiakirjat?: Asiakirja[]

  @Prop({ required: false, default: undefined })
  reservedAsiakirjaNimet?: string[]

  @Prop({
    required: false,
    type: Object,
    default: () => ({
      alkamispaiva: null,
      paattymispaiva: null,
      osaaikaprosentti: null,
      tyoskentelypaikka: {
        nimi: null,
        kunta: {},
        tyyppi: null,
        muuTyyppi: null
      },
      kaytannonKoulutus: null,
      omaaErikoisalaaTukeva: null,
      hyvaksyttyAiempaanErikoisalaan: null
    })
  })
  value!: Tyoskentelyjakso

  addedFiles: File[] = []
  newAsiakirjatMapped: Asiakirja[] = []
  deletedAsiakirjat: Asiakirja[] = []
  reservedAsiakirjaNimetMutable: string[] | undefined = []
  ensimmainenTyoskentelyjakso: Tyoskentelyjakso | null = null
  isEnsimmainenTyoskentelyjakso = false

  form: TyoskentelyjaksoForm = {
    alkamispaiva: null,
    paattymispaiva: null,
    minPaattymispaiva: null,
    maxAlkamispaiva: null,
    osaaikaprosentti: 100,
    tyoskentelypaikka: {
      nimi: null,
      kunta: { abbreviation: null },
      tyyppi: null,
      muuTyyppi: null
    },
    kaytannonKoulutus: null,
    omaaErikoisalaaTukeva: null,
    hyvaksyttyAiempaanErikoisalaan: null
  }
  tyypit = [
    { text: this.$t('terveyskeskus'), value: TyoskentelyjaksoTyyppi.TERVEYSKESKUS },
    {
      text: this.$t('keskussairaala-tai-aluesairaala'),
      value: TyoskentelyjaksoTyyppi.KESKUSSAIRAALA
    },
    {
      text: this.$t('yliopistollinen-sairaala'),
      value: TyoskentelyjaksoTyyppi.YLIOPISTOLLINEN_SAIRAALA
    }
  ]
  params = {
    saving: false,
    deleting: false
  }
  childDataReceived = false

  laillistamisTiedotForm: LaillistamistiedotLomakeKoulutettava = {
    laillistamistiedotAdded: false,
    ensimmainenTyoskentelyjakso: false,
    laillistamispaiva: null,
    laillistamispaivanLiite: null,
    laakarikoulutusSuoritettuSuomiTaiBelgia: false
  }

  async mounted() {
    this.form = {
      ...this.value
    }

    this.reservedAsiakirjaNimetMutable = this.reservedAsiakirjaNimet
    if (!this.reservedAsiakirjaNimetMutable) {
      this.reservedAsiakirjaNimetMutable = (
        await axios.get('yek-koulutettava/asiakirjat/nimet')
      ).data
    }

    this.loadLaillistamisTiedot()
    this.fetchEnsimmainenTyoskentelyjakso()

    this.childDataReceived = true
  }

  async fetchEnsimmainenTyoskentelyjakso() {
    try {
      this.ensimmainenTyoskentelyjakso = (
        await axios.get(`yek-koulutettava/ensimmainen-tyoskentelyjakso`)
      ).data
      this.isEnsimmainenTyoskentelyjakso = this.ensimmainenTyoskentelyjakso?.id === this.form.id
      this.laillistamisTiedotForm.ensimmainenTyoskentelyjakso = this.isEnsimmainenTyoskentelyjakso
    } catch {
      this.isEnsimmainenTyoskentelyjakso = false
      toastFail(this, this.$t('yek.ensimmaisen-tyoskentelyjakson-hakeminen-epaonnistui'))
    }
  }

  validateState(name: string) {
    const get = (obj: any, path: any, defaultValue = undefined) => {
      const travel = (regexp: any) =>
        String.prototype.split
          .call(path, regexp)
          .filter(Boolean)
          .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj)
      const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/)
      return result === undefined || result === obj ? defaultValue : result
    }
    const { $dirty, $error } = get(this.$v.form, name)
    return $dirty ? ($error ? false : null) : null
  }

  validateForm(): boolean {
    this.$v.form.$touch()
    return !this.$v.$anyError
  }

  async onSubmit() {
    const validations = [
      this.validateForm(),
      this.$refs.alkamispaiva ? this.$refs.alkamispaiva.validateForm() : true,
      this.$refs.paattymispaiva.validateForm(),
      this.$refs.laillistamispaiva ? this.$refs.laillistamispaiva.validateForm() : true
    ]

    if (validations.includes(false)) {
      return
    }

    if (
      !this.laillistamisTiedotForm.laillistamistiedotAdded &&
      this.laillistamisTiedotForm.laillistamispaivanLiite == null &&
      this.laillistamispaivaAsiakirjat.length > 0
    ) {
      const file = this.laillistamispaivaAsiakirjat[0]
      const data = await file.data
      if (data) {
        this.laillistamisTiedotForm.laillistamispaivanLiite = new File([data], file.nimi || '', {
          type: file.contentType || ''
        })
      }
    }

    const submitData = {
      tyoskentelyjakso: {
        ...this.form,
        tyoskentelypaikka: {
          ...this.form.tyoskentelypaikka,
          kuntaId: this.form.tyoskentelypaikka.kunta?.id
        },
        omaaErikoisalaaTukevaId: this.form.omaaErikoisalaaTukeva?.id
      },
      addedFiles: this.addedFiles,
      deletedAsiakirjaIds: this.deletedAsiakirjat.map((asiakirja) => asiakirja.id),
      laillistamistiedot: this.laillistamisTiedotForm
    }

    delete submitData.tyoskentelyjakso.asiakirjat

    this.$emit('submit', submitData, this.params)
  }

  onFilesAdded(files: File[]) {
    const addedFilesInDeletedArray = files.filter((added) =>
      this.deletedAsiakirjat.map((deleted) => deleted.nimi).includes(added.name)
    )
    const addedFilesNotInDeletedArray = files.filter(
      (added) => !addedFilesInDeletedArray.includes(added)
    )

    this.deletedAsiakirjat = this.deletedAsiakirjat?.filter(
      (deletedAsiakirja) =>
        !addedFilesInDeletedArray
          .map((addedFile) => addedFile.name)
          .includes(deletedAsiakirja.nimi)
    )
    this.addedFiles = [...this.addedFiles, ...addedFilesNotInDeletedArray]
    this.newAsiakirjatMapped = [
      ...mapFiles(addedFilesNotInDeletedArray),
      ...this.newAsiakirjatMapped
    ]
    this.$emit('skipRouteExitConfirm', false)
  }

  async onDeleteLiitetiedosto(asiakirja: Asiakirja) {
    // Jos asiakirjalla on id, on se tallennettu kantaan jo aiemmin, joten
    // lisää asiakirja poistettaviin asiakirjoihin.
    if (asiakirja.id) {
      this.deletedAsiakirjat = [asiakirja, ...this.deletedAsiakirjat]
    } else {
      // Jos asiakirjalla ei ole id:tä, on se lisätty ensimmäistä kertaa
      // tässä näkymässä, joten poista asiakirja lisättävistä tiedostoista.
      this.addedFiles = this.addedFiles?.filter((file) => file.name !== asiakirja.nimi)
      this.newAsiakirjatMapped = this.newAsiakirjatMapped?.filter(
        (a) => a.nimi !== asiakirja.nimi
      )
    }
    this.$emit('skipRouteExitConfirm', false)
  }

  onOsaaikaprosenttiInput(value: string) {
    if (value === '') {
      this.form.osaaikaprosentti = null
    } else {
      this.form.osaaikaprosentti = parseFloat(value)
    }
    this.$emit('skipRouteExitConfirm', false)
  }

  onCancel() {
    this.$emit('cancel')
  }

  get maxAlkamispaiva() {
    if (this.value.tapahtumia) {
      return this.form.maxAlkamispaiva
    } else {
      return this.form.paattymispaiva
    }
  }

  get minPaattymispaiva() {
    if (this.value.tapahtumia) {
      return this.form.minPaattymispaiva
    } else {
      return this.form.alkamispaiva
    }
  }

  get kunnatFormatted() {
    return this.kunnat
      .filter((k) => !k.korvaavaKoodi) // Rajattu pois entiset kunnat
      .filter((k) => !['000', '198', '199'].includes(k.id)) // Rajattu pois muut kuin kunnat
      .sort((a, b) => sortByAsc(a.abbreviation, b.abbreviation))
  }

  get asiakirjatTableItems() {
    return [...this.newAsiakirjatMapped, ...this.asiakirjatExcludingDeleted()]
  }

  get existingFileNamesInCurrentView() {
    return this.asiakirjatTableItems?.map((item) => item.nimi)
  }

  get existingFileNamesInOtherViews() {
    return this.reservedAsiakirjaNimetMutable?.filter(
      (nimi) => !this.existingFileNamesInCurrentView.includes(nimi)
    )
  }

  get tyyppiLabel() {
    if (this.form?.tyoskentelypaikka?.tyyppi) {
      return tyoskentelypaikkaTyyppiLabel(this, this.form?.tyoskentelypaikka?.tyyppi)
    }
    return undefined
  }

  private asiakirjatExcludingDeleted(): Asiakirja[] {
    return (this.asiakirjat ?? []).filter(
      (asiakirja) =>
        !this.deletedAsiakirjat.map((deleted) => deleted.nimi).includes(asiakirja.nimi)
    )
  }

  async loadLaillistamisTiedot() {
    try {
      const laillistamistiedot: Laillistamistiedot = (
        await axios.get('/yek-koulutettava/laillistamispaiva')
      ).data
      this.laillistamisTiedotForm.laillistamispaiva = laillistamistiedot.laillistamispaiva
      this.laillistamisTiedotForm.laillistamistiedotAdded =
        laillistamistiedot.laillistamispaiva !== null

      if (laillistamistiedot.laillistamistodistus) {
        const data = Uint8Array.from(atob(laillistamistiedot.laillistamistodistus), (c) =>
          c.charCodeAt(0)
        )
        this.laillistamispaivaAsiakirjat.push(
          mapFile(
            new File([data], laillistamistiedot.laillistamistodistusNimi || '', {
              type: laillistamistiedot.laillistamistodistusTyyppi || ''
            })
          )
        )
      }

      if (laillistamistiedot.laakarikoulutusSuoritettuSuomiTaiBelgia) {
        this.laillistamisTiedotForm.laakarikoulutusSuoritettuSuomiTaiBelgia =
          laillistamistiedot.laakarikoulutusSuoritettuSuomiTaiBelgia
      }
    } catch {
      toastFail(this, this.$t('laillistamispaivan-hakeminen-epaonnistui'))
    }
  }

  onLaillistamispaivaFilesAdded(files: File[]) {
    this.laillistamisTiedotForm.laillistamispaivanLiite = files[0]
    this.laillistamispaivaAsiakirjat.push(...mapFiles(files))
  }

  async onDeleteLaillistamispaivanLiite() {
    this.laillistamisTiedotForm.laillistamispaivanLiite = null
    this.laillistamispaivaAsiakirjat = []
  }
}
