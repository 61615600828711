









































import { Component, Vue } from 'vue-property-decorator'

import { getYliopisto } from '@/api/virkailija'
import BCardSkeleton from '@/components/card/card.vue'
import ErikoistujienSeurantaVirkailijaCard from '@/components/etusivu-cards/erikoistujien-seuranta-virkailija-card.vue'
import HenkilotiedotCard from '@/components/etusivu-cards/henkilotiedot-card.vue'
import KoejaksotCard from '@/components/etusivu-cards/koejaksot-card.vue'
import TerveyskeskuskoulutusjaksotCard from '@/components/etusivu-cards/terveyskeskuskoulutusjaksot-card.vue'
import ValmistumispyynnotCard from '@/components/etusivu-cards/valmistumispyynnot-card.vue'
import YekKoulutettavienSeurantaVirkailijaCard from '@/components/etusivu-cards/yek-koulutettavien-seuranta-virkailija-card.vue'
import YekTerveyskeskuskoulutusjaksotCard from '@/components/etusivu-cards/yek-terveyskeskuskoulutusjaksot-card.vue'
import YekvalmistumispyynnotCard from '@/components/etusivu-cards/yek-valmistumispyynnot-card.vue'
import ErikoistuvatLaakarit from '@/views/kayttajahallinta/erikoistuvat-laakarit.vue'
import Kouluttajat from '@/views/kayttajahallinta/kouluttajat.vue'
import Paakayttajat from '@/views/kayttajahallinta/paakayttajat.vue'
import Vastuuhenkilot from '@/views/kayttajahallinta/vastuuhenkilot.vue'
import Virkailijat from '@/views/kayttajahallinta/virkailijat.vue'

@Component({
  components: {
    Paakayttajat,
    ErikoistuvatLaakarit,
    Kouluttajat,
    Virkailijat,
    Vastuuhenkilot,
    BCardSkeleton,
    ErikoistujienSeurantaVirkailijaCard,
    HenkilotiedotCard,
    KoejaksotCard,
    TerveyskeskuskoulutusjaksotCard,
    ValmistumispyynnotCard,
    YekTerveyskeskuskoulutusjaksotCard,
    YekvalmistumispyynnotCard,
    YekKoulutettavienSeurantaVirkailijaCard
  }
})
export default class EtusivuVirkailija extends Vue {
  initializing = true
  loading = true
  yliopisto: string | null = null

  tabIndex = 0
  tabs = ['#erikoislaakarikoulutus', '#yekkoulutus']
  beforeMount() {
    this.tabIndex = this.tabs.findIndex((tab) => tab === this.$route.hash)
  }
  async mounted() {
    await this.fetchYliopisto()
    this.loading = false
  }

  async fetchYliopisto() {
    this.yliopisto = (await getYliopisto()).data
    this.loading = false
  }
}
